import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { deleteOrder, getOrdersList, getProfitDetails } from '../../shared/api/user';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import "../Orders/Orders.css";
import { updateOrder, getMe } from '../../shared/api/user';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import tone from '../../audio/notify.mp3';
import toast, { Toaster } from 'react-hot-toast';
import CustomizedSnackbars from '../Snackbar/Snackbar';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { inputLabelClasses } from "@mui/material/InputLabel";

export default function BasicTable() {
    const [orders, setOrders] = useState([]);
    const [Offset, setOffset] = useState(0);
    const [prevOffset, setPrevOffset] = useState(0);
    const navigate = useNavigate();
    const [isempty, setisempty] = useState(false);
    const [isloading, setisloading] = useState(true);
    const previousOrdersRef = useRef([]);
    const [isNewOrderAdded, setIsNewOrderAdded] = useState(false);
    const [notificationSent, setNotificationSent] = useState(false);
    const [latestOrderTime, setLatestOrderTime] = useState(null);
    const [error, setError] = useState("");
    const [open, setOpen] = React.useState(false);
    const [orderId, setOrderId] = useState('');
    const [time, setTime] = useState('');
    const [reason, setReason] = useState('');
    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#CB2704",
            },
        },
    };

    const init = async () => {
        let rows = await getOrdersList(Offset, 30, 'PENDING');
        setOrders(rows.data.orders);
        if (!rows.data.orders.length) {
            setisempty(true)
        }
        setisloading(false);
        // Check if there are any new orders by comparing timestamps
        const newOrders = rows.data.orders.filter(order => !previousOrdersRef.current.some(prevOrder => prevOrder._id === order._id));

        if (newOrders.length > 0) {
            const latestOrderTimestamp = moment(newOrders[0].createdAt).valueOf();
            const lastNotificationTimestamp = localStorage.getItem('lastNotificationTimestamp');
            if (lastNotificationTimestamp === null || latestOrderTimestamp > Number(lastNotificationTimestamp)) {
                // setLatestOrderTime(moment(newOrders[0].createdAt).format('YYYY-MM-DD HH:mm:ss'));
                setIsNewOrderAdded(true); // Set the flag to true if a new order was added
                setNotificationSent(false); // Reset the notificationSent flag when a new order is added
                localStorage.setItem('lastNotificationTimestamp', latestOrderTimestamp); // Store the latest order timestamp in localStorage
            }
        }

        // Update the previousOrdersRef with the current orders
        previousOrdersRef.current = rows.data.orders;
    }

    useEffect(() => {
        init();
        // Fetch orders every 15 seconds
        const interval = setInterval(() => {
            init();
        }, 15000);

        // Get previous orders from localStorage
        const previousOrders = JSON.parse(localStorage.getItem('previousOrders'));
        // Update the previousOrdersRef with the previous orders
        previousOrdersRef.current = previousOrders || [];

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        // Show notification when a new order is added
        if (isNewOrderAdded && !notificationSent) {
            showNotification();
            setNotificationSent(true);
        }
    }, [isNewOrderAdded, notificationSent]);

    const showNotification = async () => {
        let audio = new Audio(tone);
        audio.play();
        notify();
    }

    const notify = () => toast('A new order has been received.');

    // async function deleteOrderById(id) {
    //     let status = await deleteOrder(id);
    //     init();
    // }.

    const handleClickOpen = (id) => {
        setOpen(true);
        setOrderId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function updateOrderById(id) {
        try {
            toast('Order Accepted');
            updateOrder(id, { orderStatus: 'ACCEPTED', estTime: time, delayReason: reason });
            setOpen(false);
            setTime('')
            init();
        }
        catch (err) {
            console.log(err)
            await updateOrder(id, { orderStatus: 'PENDING' });
            setError(`There is an issue with this user payment. ${err.response.data.status.message.raw.message}`);
            setTimeout(() => {
                setError('');
                setOpen(false);
            }, 6000);
        }
    }

    const details = async (id) => {
        navigate(`/order/${id}`);
    }

    const editDetails = async (id) => {
        navigate(`/order/edit/${id}`);
    }

    async function paginationClicked(e) {
        setOffset(Number(e.target.innerText * 10))
        let rows = await getOrdersList(Offset);
        let data = rows.data.orders.filter(function (e) {
            return e.orderStatus === 'PENDING';
        });
        setOrders(data);
    }

    const paginationClickedNext = () => {
        setOffset((prevOffset) => prevOffset + 30);
        init();
    }

    async function paginationClickedPrev() {
        if (Offset > 0) {
            setOffset(Offset - 30);
            init();
        }
    }

    return (
        <div>
            {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
            {isloading && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="warning" />
            </Box>}
            {(isempty && !isloading) && <div style={{ fontWeight: '600', textAlign: 'center' }}>No New Orders Found!</div>}
            {(!isempty && !isloading) && <TableContainer className='table-container' component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className='table-head'>
                        <TableRow>
                            <TableCell className='table-call-row'>Dish</TableCell>
                            <TableCell className='table-call-row' align="right">Customer Name</TableCell>
                            <TableCell className='table-call-row' align="right">Dish Name</TableCell>
                            {/* <TableCell className='table-call-row' align="right">Order Status</TableCell> */}
                            <TableCell className='table-call-row' align="right">Order Type</TableCell>
                            <TableCell className='table-call-row' align="right">Payment Status</TableCell>
                            <TableCell className='table-call-row' align="right">Total Price</TableCell>
                            {/* <TableCell align="center">Details</TableCell> */}
                            <TableCell className='table-call-row' align="right">Edit Order</TableCell>
                            <TableCell className='table-call-row' align="right">Order Time</TableCell>
                            <TableCell className='table-call-row' align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((row) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell className='table-call-row-data' onClick={() => editDetails(row._id)} size='small' component="th" scope="row">
                                    <img style={{ height: "45px", width: "45px", borderRadius: "50%", cursor: 'pointer' }} src={row.orderItems[0]?.dish.picture} ></img>
                                </TableCell>
                                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.user?.firstName} {row.user?.lastName}</TableCell>
                                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderItems[0]?.dish.dishName.substring(0, 25)}...</TableCell>
                                {/* <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderStatus}</TableCell> */}
                                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderType}</TableCell>
                                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right"><span className={`${row?.paymentStatus === 'PAID' ? 'payment-status' : 'Npayment-status'}`}>{row?.paymentStatus}</span></TableCell>
                                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">${(row.subTotal - row.chefServiceFee).toFixed(2)}</TableCell>
                                {/* <TableCell align="right"><Button sx={{ color: '#CB2704' }} onClick={() => details(row._id)} variant="text"><InfoIcon sx={{ color: '#CB2704' }}></InfoIcon></Button></TableCell> */}
                                <TableCell align="right"><Button disabled onClick={() => editDetails(row._id)} variant="text"><EditIcon sx={{ color: '#CB2704' }}></EditIcon></Button></TableCell>
                                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{new Date(row.createdAt).toLocaleString()}</TableCell>
                                <TableCell align="right"><Button sx={{ color: '#CB2704', fontWeight: '600' }} onClick={() => handleClickOpen(row._id)} variant="text">Accept</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Confirm Order</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            How much time you need to make order?
                        </DialogContentText>
                        <TextField
                            sx={style}
                            InputLabelProps={{
                                sx: {
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#CB2704"
                                    }
                                }

                            }}
                            autoFocus
                            margin="dense"
                            id="time"
                            label="Time in minutes"
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setTime(e.target.value);
                            }}

                        />
                        {time > 15 &&<TextField
                            sx={style}
                            InputLabelProps={{
                                sx: {
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#CB2704"
                                    }
                                }

                            }}
                            margin="dense"
                            id="reason"
                            label="Reason"
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}

                        />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button className='inmenu-btn' onClick={handleClose}>Cancel</Button>
                        <Button className='inmenu-btn' onClick={() => updateOrderById(orderId)}>Submit</Button>
                    </DialogActions>
                </Dialog>
                <Box textAlign='center' mb={2} mt={2}>
                    <Button onClick={() => paginationClickedNext()} variant="text" sx={{ color: '#CB2704', fontWeight: '600' }}>Load next</Button>
                    {Offset > 0 && <Button onClick={() => paginationClickedPrev()} variant="text" sx={{ color: '#CB2704', fontWeight: '600' }}>Load previous</Button>}
                </Box>

            </TableContainer>}
            <Toaster />
        </div>
    );
}