import * as React from 'react';
import "./orderDetail.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useState } from 'react';
import moment from 'moment';
import { updateOrder } from '../../shared/api/user';
import { useParams } from "react-router-dom";
import { getSingleOrder } from '../../shared/api/user';
import { useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

export default function BasicTable() {
    const [orders, setOrders] = useState([]);
    const [dishes, setDishes] = useState([]);
    const [addons, setAddons] = useState([]);
    const { id } = useParams();

    const init = async () => {
        let rows = await getSingleOrder(id);
        setOrders(rows.data.order);
        let dishes = [];
        let addons = [];
        setDishes(dishes)
        rows.data.order.orderItems.forEach((e => {
            e.dish.quantity = e.quantity;
            if (e.dish.price === 0 || e.dish.price === null) {
                e.size.forEach((s => {
                    e.dish.sizes.forEach((size => {
                        if (s.size === size._id) {
                            size.quantity = s.quantity;
                            size.crust = s.crust;
                            size.flavour = s.flavour;
                            size.sauce = s.sauce;
                            size.dish = e.dish;
                            dishes.push(size)
                        }
                    }))
                }))
            } else {
                dishes.push(e.dish);
            }
            console.log(dishes)
        }))
        rows.data.order.orderItems.forEach((e => {
            e.addons.forEach((a => {
                e.dish.addons.forEach((addon => {
                    if (a.addon === addon._id) {
                        addon.quantity = a.quantity;
                        addons.push(addon);
                    }
                }))
            }))
        }))
        setAddons(addons)
    }


    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        init();
    }, []);

    async function updateOrderDetails(id) {
        let status = await updateOrder(id, { orderStatus: 'CANCELLED' });
        init();
    }

    return (
        <div>
            <div className='items-details'>
                <TableContainer className='table-container' sx={{ minWidth: 550, maxWidth: 700 }} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead className='table-head'>
                            <TableRow>
                                <TableCell className='table-call-row'>Dish Name</TableCell>
                                <TableCell className='table-call-row' align="right">Quantity</TableCell>
                                <TableCell className='table-call-row' align="right">Description</TableCell>
                                <TableCell className='table-call-row' align="right">Size</TableCell>
                                <TableCell className='table-call-row' align="right">Flavour</TableCell>
                                <TableCell className='table-call-row' align="right">Crust</TableCell>
                                <TableCell className='table-call-row' align="right">Sauce</TableCell>
                                <TableCell className='table-call-row' align="right">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dishes.map((row) => (
                                <TableRow
                                    key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    {!row.size && <TableCell className='table-call-row-data' component="th" scope="row">{row.dishName.substring(0, 18)}...</TableCell>}
                                    {row.size && <TableCell className='table-call-row-data' component="th" scope="row">{row.dish.dishName.substring(0, 18)}...</TableCell>}
                                    {row.size && <TableCell className='table-call-row-data' align="center">{row.size}</TableCell>}
                                    {row.offers && <TableCell className='table-call-row-data' align="center">{row.quantity * 2}</TableCell>}
                                    {!row.offers && <TableCell className='table-call-row-data' align="center">{row.quantity}</TableCell>}
                                    {!row.size && <TableCell className='table-call-row-data' align="right">{row.description.substring(0, 18)}...</TableCell>
                                    }
                                    {row.size && <TableCell className='table-call-row-data' align="right">{row.dish.description.substring(0, 18)}...</TableCell>
                                    }
                                    {row.size && <TableCell className='table-call-row-data' align="center">{row.flavour}</TableCell>}
                                    {row.size && <TableCell className='table-call-row-data' align="center">{row.crust}</TableCell>}
                                    {row.size && <TableCell className='table-call-row-data' align="center">{row.sauce}</TableCell>}
                                    <TableCell className='table-call-row-data' align="right">${row.price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer className='table-container' sx={{ mt: 3, minWidth: 550, maxWidth: 700 }} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead className='table-head'>
                            <TableRow>
                                <TableCell className='table-call-row'>Addon Name</TableCell>
                                <TableCell className='table-call-row' align="right">Quantity</TableCell>
                                <TableCell className='table-call-row' align="right">Description</TableCell>
                                <TableCell className='table-call-row' align="right">Addon Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {addons.map((row) => (
                                <TableRow
                                    key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell className='table-call-row-data' component="th" scope="row">{row.addonName}</TableCell>
                                    <TableCell className='table-call-row-data' align="right">{row.quantity}</TableCell>
                                    <TableCell className='table-call-row-data' align="right">{orders.description}</TableCell>
                                    <TableCell className='table-call-row-data' align="right">${row.addonPrice}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div>
                <div className='pricingdel'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='title-details'>Order Details</div>
                        {orders.orderStatus === 'PENDING' && <Button onClick={() => updateOrderDetails(id)} sx={{ mb: 2, color: '#CB2704', fontWeight: '600', border: '1px solid #CB2704', "&:hover": { border: "1px solid red" } }} variant="outlined" style={{ float: "right" }} startIcon={<ClearIcon sx={{ color: '#CB2704' }} />}>
                            Cancel Order
                        </Button>}
                    </div>
                    <div className='total-flex'>
                        <div><b>Customer Name</b></div>
                        <div>{orders.user?.firstName} {orders.user?.lastName}</div>
                    </div>
                    <div className='total-flex'>
                        <div><b>Order Time</b></div>
                        <div>{moment(orders.createdAt).format('MMMM Do YYYY, h:mm a')}</div>
                    </div>
                    <div className='total-flex'>
                        <div><b>Order Status</b></div>
                        <div>{orders.orderStatus}</div>
                    </div>
                    <div className='total-flex'>
                        <div><b>Order Type</b></div>
                        <div>{orders.orderType}</div>
                    </div>
                    <div className='total-flex'>
                        <div><b>Payment Status</b></div>
                        <div>{orders.paymentStatus}</div>
                    </div>
                    <div className='total-flex'>
                        <div><b>Sub Total</b></div>
                        <div>${orders.subTotal}</div>
                    </div>
                    <div className='total-flex'>
                        <div><b>Service Fee & Other Charges</b></div>
                        <div>${orders.chefServiceFee}</div>
                    </div>
                    <div className='total-flex'>
                        <div><b>Total</b></div>
                        <div><b>${(orders.subTotal - orders.chefServiceFee).toFixed(2)}</b></div>
                    </div>
                    <div className='total-flex'>
                        <div><b>Order Notes</b></div>
                        <div>{orders.notes}</div>
                    </div>
                    <div>Note: Total amount will be deposited to your account.</div>

                </div>
            </div>
        </div>
    );
}