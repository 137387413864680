import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import { getLast24HoursOrders, getMe } from '../../shared/api/user';
import { useState, useEffect } from 'react';

function createData(time, amount) {
  return { time, amount };
}

export default function Chart() {
  const theme = useTheme();
  const [User, setUser] = useState([]);
  const [data, setData] = useState([]);

  const init = async () => {
    let user = await getMe();
    setUser(user.data.user);
    let orders = await getLast24HoursOrders(user.data.user._id)
    let data = [
      {time: '00:00', amount: 0}
    ];
    orders.data.orders.forEach(element => {
      let date = element.createdAt.split('T')
      let time = date[1].split('.');
      let time2 = time[0].split(':');
      data.push({time: `${time2[0]}:${time2[1]}`, amount: element.total});
    });
    setData(data);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <React.Fragment>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="time"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Sales ($)
            </Label>
          </YAxis>
          <Line
            isAnimationActive={true}
            type="monotone"
            dataKey="amount"
            stroke={'#ff0000'}
            dot={true}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

