import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./Topdishes.css";
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { getMe, getPopular } from '../../shared/api/user';
import * as React from 'react';


function TopDishes() {
    const [User, setUser] = useState([]);
    const [TopDishes, setTopDishes] = useState([]);
    const [last24, setLast24] = useState([]);
    const [isempty, setisempty] = useState(false);
    const [isloading, setisloading] = useState(true);
    const [open, setOpen] = useState(true);

    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
        let orders = await getPopular(user.data.user._id);
        console.log(orders)
        setLast24(orders.data.last24)
        let AllDishes = [];
        orders.data.orders.forEach(element => {
            AllDishes.push(element.orderItems[0].dish)
        });
        AllDishes = AllDishes.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t._id === value._id && t._id === value._id
            ))
        )
        AllDishes = AllDishes.slice(0, 3)
        setTopDishes(AllDishes)
        setisloading(false)
    }

    useEffect(() => {
        init();
    }, []);
    const toggleDrawer = () => {
        setOpen(!open);
    };


    return (
        <div style={{ marginBottom: '40px', display: 'flex', justifyContent: 'space-between' }}>
            {(!isempty && !isloading) && <div className='last-order'>
                <div style={{ fontSize: 42, fontWeight: 600 }}>{last24?.length || 0}</div>
                <div style={{ fontSize: 18, fontWeight: 600 }}>Orders</div>
                <div>in last 24 hours.</div>
            </div>
            }
            <div style={{ width: '1170px' }}>
                {(!isempty && !isloading) && <Typography
                    component="h1"
                    variant="h5"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, fontWeight: '600', marginBottom: '10px' }}
                >
                    Top 3 dishes
                </Typography>
                }
                {/* {isloading && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="warning" />
              </Box>} */}
                {/* {(isempty && !isloading) && <div style={{ fontWeight: '600', textAlign: 'center' }}>No Dishes! You can add from menu.</div>} */}
                {(!isempty && !isloading) && <TableContainer className='table-container-d' component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className='table-head'>
                            <TableRow>
                                <TableCell className='table-call-row'>Dish</TableCell>
                                <TableCell className='table-call-row' align="right">Dish Name</TableCell>
                                <TableCell className='table-call-row' align="right">Description</TableCell>
                                <TableCell className='table-call-row' align="right">Price</TableCell>
                                <TableCell className='table-call-row' align="right">Cuisine</TableCell>
                                <TableCell className='table-call-row' align="right">Category</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {TopDishes.map((row) => (
                                <TableRow
                                    key={row._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell className='table-call-row-data' size='small' component="th" scope="row">
                                        <img style={{ height: "50px", width: "50px", borderRadius: "50%" }} src={row.picture} ></img>
                                    </TableCell>
                                    <TableCell className='table-call-row-data' align="right">{row.dishName.substring(0, 25)}...</TableCell>
                                    <TableCell className='table-call-row-data' align="right">{row.description.substring(0, 40)}...</TableCell>
                                    <TableCell className='table-call-row-data' align="right">{`$${row.price}`}</TableCell>
                                    <TableCell className='table-call-row-data' align="right">{row.cuisine?.title}</TableCell>
                                    <TableCell className='table-call-row-data' align="right">{row?.category}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </div>
        </div>
    )
}

export default TopDishes;
