import http from './http';
// var axios = require('axios');
import axios, * as others from 'axios';
let apiKey = 'AIzaSyAgCpBoiRucycmmhOPp9o4qd6_vdR6vz3Y';

export const getMe = () => {
  return http.get('api/v1/users/profile').then(res => res.data);
};

export const login = (body) => {
  return http.post('api/v1/users/login', body).then(res => res.data);
};

export const getOrdersList = (offset, limit, orderStatus) => {
  return http.get(`api/v1/orders?limit=${limit}&offset=${offset}&orderStatus=${orderStatus}&role=CHEF`).then(res => res.data);
};

export const getKitchensList = () => {
  return http.get(`/api/v1/kitchens`).then(res => res.data);
};

export const getAllDishes = (id) => {
  return http.get(`api/v1/dishes/?role=CHEF&_id=${id}`).then(res => res.data);
};

export const deleteUser = (id) => {
  return http.delete(`api/v1/users/${id}`).then(res => res.data);
};

export const deleteOrder = (id) => {
  return http.delete(`api/v1/orders/${id}`).then(res => res.data);
};

export const deleteDish = (id) => {
  return http.delete(`api/v1/dishes/${id}`).then(res => res.data);
};

export const deleteKitchen = (id) => {
  return http.delete(`api/v1/kitchens/${id}`).then(res => res.data);
};

export const getProfitDetails = () => {
  return http.get(`/api/v1/financials`).then(res => res.data);
};

export const getDietary = () => {
  return http.get(`/api/v1/dietaries`).then(res => res.data);
};

export const getCategory = () => {
  return http.get(`/api/v1/categories`).then(res => res.data);
};

export const getCuisine = () => {
  return http.get(`/api/v1/cuisines`).then(res => res.data);
};

export const uploadImage = async (file) => {
  const response = await http({
    method: "post",
    url: "/api/s3",
    data: file,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const uploadImageWithoutSize = async (file) => {
  const response = await http({
    method: "post",
    url: "/api/withoutsize/s3",
    data: file,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const addDish = (body) => {
  return http.post(`/api/v1/dishes`, body).then(res => res.data);
};

export const updateUser = (id, body) => {
  return http.put(`api/v1/users/${id}`, body).then(res => res.data);
};

export const updateOrder = (id, body) => {
  return http.put(`api/v1/orders/${id}`, body).then(res => res.data);
};

export const getSingleOrder = (id) => {
  return http.get(`api/v1/orders/${id}`).then(res => res.data);
};

export const connectStripe = (body) => {
  return http.post(`/api/v1/dishes`, body).then(res => res.data);
};

export const createCoupon = (body) => {
  return http.post(`/api/v1/payments/stripe/coupon`, body).then(res => res.data);
};

export const getCoupon = (code) => {
  return http.get(`/api/v1/payments/stripe/coupon/${code}`).then(res => res.data);
};

export const deleteCoupon = (code) => {
  return http.delete(`/api/v1/payments/stripe/coupon/delete/${code}`).then(res => res.data);
};

export const updateDish = (id, body) => {
  return http.put(`api/v1/dishes/${id}`, body).then(res => res.data);
};

export const getDishById = (id, body) => {
  return http.get(`api/v1/dishes/${id}`, body).then(res => res.data);
};

export const signup = (body) => {
  return http.post('api/v1/users/signup', body).then(res => res.data);
};

export const forgotPassword = (body) => {
  return http.post(`api/v1/users/forgot-password`, body).then(res => res.data);
};

export const changePassword = (body) => {
  return http.post(`api/v1/users/change-password`, body).then(res => res.data);
};

export const loginWithGoogle = (body) => {
  return http.post(`api/v1/users/loginsocial/google`, body).then(res => res.data);
};

export const getPopular = (id) => {
  return http.get(`api/v1/orders/popular/byChef/${id}`).then(res => res.data);
};

export const autoComplete = (input, token) => {
  return http.get(`api/v1/google/autocomplete/${input}/${token}`).then(res => res.data);
};

export const placeDetail = (id, token) => {
  return http.get(`api/v1/google/placedetails/${id}/${token}`).then(res => res.data);
};

export const verifyEmail = (body) => {
  return http.post(`api/v1/users/verify-email`, body).then(res => res.data);
};

export const verificationEmail = (body) => {
  return http.post(`api/v1/users/verification-email`, body).then(res => res.data);
};

export const getLast24HoursOrders = (id) => {
  return http.get(`api/v1/orders/last24hours/${id}`).then(res => res.data);
};