import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./EditDish.css";
import { getCategory, updateDish } from "../../shared/api/user";
import { getCuisine } from "../../shared/api/user";
import {
  getDietary,
  createCoupon,
  getDishById,
  getMe,
} from "../../shared/api/user";
import { Formik, Form } from "formik";
import { uploadImage } from "../../shared/api/user";
import CustomizedSnackbars from "../Snackbar/Snackbar";
import { useNavigate } from "react-router-dom";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { inputLabelClasses } from "@mui/material/InputLabel";

const theme = createTheme();
export default function EditDish() {
  const [open, setOpen] = React.useState(false);
  const [openSize, setOpenSize] = React.useState(false);
  const [openSauce, setOpenSauce] = React.useState(false);
  const [openCrust, setOpenCrust] = React.useState(false);
  const [openFlavour, setOpenFlavour] = React.useState(false);
  const [Category, setCategory] = React.useState([]);
  const [Cuisine, setCuisine] = React.useState([]);
  const [Dietary, setDietary] = React.useState([]);
  const [File, setFile] = React.useState();
  const [Addon, setAddon] = React.useState([]);
  const [sauces, setSauces] = React.useState([]);
  const [crusts, setCrusts] = React.useState([]);
  const [flavours, setFlavours] = React.useState([]);
  const [sizes, setSizes] = React.useState([]);
  const [flavour, setFlavour] = React.useState('');
  const [crust, setCrust] = React.useState('');
  const [sauce, setSauce] = React.useState('');
  const [error, setError] = useState("");
  const [opened, setOpened] = React.useState(false);
  const [couponName, setcouponName] = useState("");
  const [couponCode, setcouponCode] = useState("");
  const [couponAmount, setcouponAmount] = useState("");
  const [couponType, setcouponType] = useState("");
  const [Duration, setDuration] = useState("");
  const [Redemptions, setRedemptions] = useState("");
  const [Loading, setLoading] = useState(true);
  const [disablePrice, setDisablePrice] = React.useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  let [dish, setDish] = useState([]);
  const [imageSelected, setImageSelected] = useState(false);
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#CB2704",
      },
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFile = (event) => {
    setFile(event.target.files[0]);
    setImageSelected(true);
  };

  const handleClickOpened = () => {
    setOpened(true);
  };

  const handleClickClosed = () => {
    setOpened(false);
  };

  const submitCoupon = async () => {
    let code = couponCode.toUpperCase();
    setcouponCode(code);
    let body = {
      name: couponName,
      duration_in_months: Duration,
      code: code,
      max_redemptions: Redemptions,
    };
    if (couponType === "percentage") {
      body.percent_off = couponAmount;
    } else {
      let finalAmount = (couponAmount * 100).toFixed(0);
      body.amount_off = finalAmount;
      body.currency = "USD";
    }
    await createCoupon(body);
    setOpened(false);
  };

  const init = async () => {
    let user = await getMe();
    setCategory(user.data.user.categories);
    let dish = await getDishById(id);
    // let categories = await getCategory();
    let dietaries = await getDietary();
    let cuisine = await getCuisine();
    // setCategory(categories.data.categories);
    setCuisine(cuisine.data.cuisines);
    setDietary(dietaries.data.dietaries);
    setDish(dish.data.dishe);
    setAddon(dish.data.dishe.addons);
    setSizes(dish.data.dishe.sizes);
    setFlavours(dish.data.dishe.flavours);
    setSauces(dish.data.dishe.sauces);
    setCrusts(dish.data.dishe.crust);
    setLoading(false);
    if (dish.data.dishe.price === 0 || dish.data.dishe.price === null) {
      setDisablePrice(true);
    }
  };

  const deleteSize = async (e) => {
    sizes.forEach(element => {
      if (element.size === e.target.innerText) {
        var index = sizes.indexOf(element);
        sizes.splice(index, 1);
        setSizes(sizes);
      }
    });
    await updateDish(dish._id, { sizes: sizes });
    init();
  }

  const deleteFlavour = async (e) => {
    flavours.forEach(element => {
      if (element === e.target.innerText) {
        var index = flavours.indexOf(element);
        flavours.splice(index, 1);
        setFlavours(flavours);
      }
    });
    await updateDish(dish._id, { flavours: flavours });
    init();
  }

  const deleteSauces = async (e) => {
    sauces.forEach(element => {
      if (element === e.target.innerText) {
        var index = sauces.indexOf(element);
        sauces.splice(index, 1);
        setSauces(sauces);
      }
    });
    await updateDish(dish._id, { sauces: sauces });
    init();
  }

  const deleteCrust = async (e) => {
    crusts.forEach(element => {
      if (element === e.target.innerText) {
        var index = crusts.indexOf(element);
        crusts.splice(index, 1);
        setCrusts(crusts);
      }
    });
    await updateDish(dish._id, { crust: crusts });
    init();
  }

  const deleteAddon = async (e) => {
    Addon.forEach(element => {
      if (element === e.target.innerText) {
        var index = Addon.indexOf(element);
        Addon.splice(index, 1);
        setAddon(Addon);
      }
    });
    await updateDish(dish._id, { addons: Addon });
    init();
  }

  const handleClickOpenSize = () => {
    setOpenSize(true);
  };

  const handleCloseSize = () => {
    setOpenSize(false);
  };

  const handleClickOpenSauce = () => {
    setOpenSauce(true);
  };

  const handleCloseSauce = () => {
    setOpenSauce(false);
  };

  const handleClickOpenCrust = () => {
    setOpenCrust(true);
  };

  const handleCloseCrust = () => {
    setOpenCrust(false);
  };

  const handleSauceAdd = (e) => {
    setSauce(e);
  };

  const submitSauce = () => {
    sauces.push(sauce)
    setSauces(sauces);
    setOpenSauce(false)
  }

  const handleCrustAdd = (e) => {
    setCrust(e);
  };

  const submitCrust = () => {
    crusts.push(crust)
    setCrusts(crusts);
    setOpenCrust(false)
  }

  const handleClickOpenFlavour = () => {
    setOpenFlavour(true);
  };

  const handleCloseFlavour = () => {
    setOpenFlavour(false);
  };

  const handleFlavourAdd = (e) => {
    setFlavour(e);
  };

  const submitFlavour = () => {
    flavours.push(flavour)
    setFlavours(flavours);
    setOpenFlavour(false)
  }

  useEffect(() => {
    init();
  }, []);

  const updateAvailablility = (action) => {
    updateDish(dish._id, {isAvailable: action});
    init();
  };

  if (!Loading) {
    return (
      <ThemeProvider theme={theme}>
        {error && (
          <CustomizedSnackbars
            msg={error}
            isOpen={error !== ""}
          ></CustomizedSnackbars>
        )}
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ mt: 2 }} component="h1" variant="h5">
              Edit dish
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {console.log(dish)}
                <Formik
                  initialValues={{
                    dishName: dish.dishName,
                    description: dish.description,
                    price: dish.price,
                    category: dish.category,
                    cuisine: dish.cuisine?._id,
                    dietary: dish?.dietary?._id,
                    offers: dish.offers,
                    picture: dish.picture,
                    addons: dish.addons,
                    status: "PENDING",
                    coupon: dish.coupon,
                    sizes: dish.sizes,
                    flavours: dish.flavours,
                    crust: dish.crust,
                    sauces: dish.sauces,
                    estCookingTime: dish.estCookingTime,
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(async () => {
                      if (File) {
                        const formData = new FormData();
                        formData.append("file", File);
                        let res = await uploadImage(formData);
                        values.picture = res.data.data.url;
                      }
                      if (Addon) {
                        values.addons = Addon;
                      }
                      if (sizes) {
                        values.sizes = sizes;
                      }
                      if (couponCode) {
                        values.coupon = couponCode;
                      }
                      if (sauces) {
                        values.sauces = sauces;
                      }
                      if (crusts) {
                        values.crust = crusts;
                      }
                      if (flavours) {
                        values.flavours = flavours;
                      }
                      values.price = parseFloat(values.price);
                      let dish = await updateDish(id, values);
                      setSubmitting(false);
                      setError("Dish Updated!");
                      navigate("/dishes");
                    }, 400);
                  }}
                >
                  {({ handleSubmit, handleChange, isSubmitting, values }) => (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <Grid mb={1} item xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InputLabel>Dish Name</InputLabel>
                          {dish.isAvailable && <a onClick={() => updateAvailablility(false)} style={{ color: '#CB2704', cursor: 'pointer' }}>Temporary Disable</a>}
                          {!dish.isAvailable && <a onClick={() => updateAvailablility(true)} style={{ color: '#CB2704', cursor: 'pointer' }}>Enable</a>}
                        </Box>
                        <TextField
                          fullWidth
                          id="dishName"
                          name="dishName"
                          autoComplete="text"
                          onChange={handleChange}
                          value={values.dishName}
                        />
                      </Grid>
                      {console.log(values)}
                      <Grid mb={1} item xs={12}>
                        <InputLabel>Description</InputLabel>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          autoComplete="text"
                          onChange={handleChange}
                          value={values.description}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel id="demo-simple-select-label">
                          Estimated Cooking time in minutes
                        </InputLabel>
                        <TextField
                          sx={style}
                          InputLabelProps={{
                            sx: {
                              [`&.${inputLabelClasses.shrink}`]: {
                                color: "#CB2704",
                              },
                            },
                          }}
                          fullWidth
                          id="estCookingTime"
                          name="estCookingTime"
                          type="text"
                          autoComplete="text"
                          onChange={handleChange}
                          value={values.estCookingTime}
                        />
                      </Grid>
                      {/* <Grid mb={1} item xs={12}>
                        <InputLabel>Price</InputLabel>
                        <TextField
                          fullWidth
                          id="price"
                          name="price"
                          type="text"
                          autoComplete="text"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="category"
                          name="category"
                          value={values.category}
                          label="Category"
                          onChange={handleChange}
                        >
                          {Category.map((Category) => (
                            <MenuItem id={Category} value={Category}>
                              {Category}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel id="demo-simple-select-label">
                          Cuisine
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="cuisine"
                          name="cuisine"
                          value={values.cuisine}
                          label="Cuisine"
                          onChange={handleChange}
                        >
                          {Cuisine.map((Cuisine) => (
                            <MenuItem id={Cuisine._id} value={Cuisine._id}>
                              {Cuisine.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel id="demo-simple-select-label">
                          Dietary
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="dietary"
                          name="dietary"
                          value={values.dietary}
                          label="Dietary"
                          onChange={handleChange}
                        >
                          {Dietary.map((Dietary) => (
                            <MenuItem id={Dietary._id} value={Dietary._id}>
                              {Dietary.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel id="demo-simple-select-label">
                          Offers
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="offers"
                          name="offers"
                          value={values.offers}
                          label="Offers"
                          onChange={handleChange}
                        >
                          <MenuItem value="BUY_ONE_GET_ONE_FREE">
                            Buy One get one free
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Box sx={{ display: 'flex' }}>
                        <Grid item xs={12}>
                          <InputLabel id="demo-simple-select-label">
                            Price
                          </InputLabel>
                          <TextField
                            sx={style}
                            InputLabelProps={{
                              sx: {
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "#CB2704",
                                },
                              },
                            }}
                            id="price"
                            name="price"
                            type="text"
                            value={values.price}
                            autoComplete="text"
                            onChange={handleChange}
                            disabled={disablePrice}
                          />
                        </Grid>
                        <Button onClick={handleClickOpenSize} sx={{ border: "1px solid #CB2704", width: 200, height: 55, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Size</Button>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleClickOpenCrust} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Crust Option</Button>
                        <Button onClick={handleClickOpenSauce} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Sauces Option</Button>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleClickOpenFlavour} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Flavour Option</Button>
                        <Button onClick={handleClickOpen} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Addons</Button>
                      </Box>

                      {Addon?.length > 0 &&
                        <Box>
                          <div style={{ marginTop: '10px', fontWeight: 600 }}>Addons</div>
                          {Addon?.map((row) =>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '5px' }}>
                              <div style={{ cursor: 'pointer' }} onClick={(e) => deleteAddon(e)}>{row.addonName}<DeleteIcon sx={{ color: '#CB2704', float: 'left', fontSize: '19px' }} ></DeleteIcon></div>
                              <div>${row.addonPrice}</div>
                            </div>
                          )}
                        </Box>
                      }

                      {sizes?.length > 0 &&
                        <Box>
                          <div style={{ marginTop: '10px', fontWeight: 600 }}>Sizes</div>
                          {sizes?.map((row) =>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '5px' }}>
                              <div style={{ cursor: 'pointer' }} onClick={(e) => deleteSize(e)}>{row.size}<DeleteIcon sx={{ color: '#CB2704', float: 'left', fontSize: '19px' }} ></DeleteIcon></div>
                              <div>${row.price}</div>
                            </div>
                          )}
                        </Box>
                      }


                      {flavours?.length > 0 &&
                        <Box>
                          <div style={{ marginTop: '10px', fontWeight: 600 }}>Flavours</div>
                          {flavours?.map((row) =>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '5px' }}>
                              <div style={{ cursor: 'pointer' }} onClick={(e) => deleteFlavour(e)}>{row}<DeleteIcon sx={{ color: '#CB2704', float: 'left', fontSize: '19px' }} ></DeleteIcon></div>
                            </div>
                          )}
                        </Box>
                      }


                      {sauces?.length > 0 &&
                        <Box>
                          <div style={{ marginTop: '10px', fontWeight: 600 }}>Sauces</div>
                          {sauces?.map((row) =>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '5px' }}>
                              <div style={{ cursor: 'pointer' }} onClick={(e) => deleteSauces(e)}>{row}<DeleteIcon sx={{ color: '#CB2704', float: 'left', fontSize: '19px' }} ></DeleteIcon></div>
                            </div>
                          )}
                        </Box>
                      }

                      {crusts?.length > 0 &&
                        <Box>
                          <div style={{ marginTop: '10px', fontWeight: 600 }}>Crusts</div>
                          {crusts?.map((row) =>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '5px' }}>
                              <div style={{ cursor: 'pointer' }} onClick={(e) => deleteCrust(e)}>{row}<DeleteIcon sx={{ color: '#CB2704', float: 'left', fontSize: '19px' }} ></DeleteIcon></div>
                            </div>
                          )}
                        </Box>
                      }

                      <div style={{ textAlign: "center", marginTop: "20px" }}>
                        {imageSelected ? ( // Render the selected image if imageSelected is true
                          <img
                            src={URL.createObjectURL(File)}
                            alt="Selected Image"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "200px",
                              borderRadius: "10px",
                            }}
                          />
                        ) : (
                          dish &&
                          dish.picture && ( // Render the existing image
                            <img
                              src={dish.picture}
                              alt="Dish Image"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                borderRadius: "10px",
                              }}
                            />
                          )
                        )}
                        <Button
                          variant="contained"
                          component="label"
                          fullWidth
                          sx={{
                            mt: 3,
                            mb: 1,
                            background: "#CB2704",
                            "&:hover": { background: "red" },
                          }}
                        >
                          Upload Image
                          <input type="file" onChange={handleFile} hidden />
                        </Button>
                      </div>
                      <Button
                        onClick={handleClickOpened}
                        variant="contained"
                        component="label"
                        fullWidth
                        sx={{
                          mt: 1,
                          mb: 2,
                          background: "#CB2704",
                          "&:hover": { background: "red" },
                        }}
                      >
                        Coupon
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 2,
                          background: "#CB2704",
                          "&:hover": { background: "red" },
                        }}
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </Formik>
              </Grid>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Addon</DialogTitle>
                <DialogContent>
                  <Formik
                    initialValues={{ addonName: "", addonPrice: 0 }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        values.addonPrice = parseFloat(values.addonPrice);
                        Addon.push(values);
                        console.log(Addon);
                        setAddon(Addon);
                        setSubmitting(false);
                        setOpen(false);
                      }, 400);
                    }}
                  >
                    {({ handleSubmit, handleChange, isSubmitting }) => (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="addonName"
                          name="addonName"
                          label="Addon name"
                          type="text"
                          fullWidth
                          variant="standard"
                          onChange={handleChange}
                        />
                        <TextField
                          autoFocus
                          margin="dense"
                          id="addonPrice"
                          name="addonPrice"
                          label="Price"
                          type="text"
                          fullWidth
                          variant="standard"
                          onChange={handleChange}
                        />

                        <DialogActions>
                          <Button onClick={handleClose}>Cancel</Button>
                          <Button type="submit" disabled={isSubmitting}>
                            Submit
                          </Button>
                        </DialogActions>
                      </form>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>
              <Dialog open={openSize} onClose={handleCloseSize}>
                <DialogTitle>Add Size</DialogTitle>
                <DialogContent>
                  <Formik
                    initialValues={{ size: "", price: 0 }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        values.price = parseFloat(values.price);
                        sizes.push(values);
                        setSizes(sizes);
                        setSubmitting(false);
                        setOpenSize(false);
                        setDisablePrice(true);
                      }, 400);
                    }}
                  >
                    {({ handleSubmit, handleChange, isSubmitting }) => (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="size"
                          name="size"
                          label="Size"
                          type="text"
                          fullWidth
                          variant="standard"
                          onChange={handleChange}
                        />
                        <TextField
                          margin="dense"
                          id="price"
                          name="price"
                          label="Price"
                          type="text"
                          fullWidth
                          variant="standard"
                          onChange={handleChange}
                        />

                        <DialogActions>
                          <Button onClick={handleCloseSize}>Cancel</Button>
                          <Button type="submit" disabled={isSubmitting}>
                            Submit
                          </Button>
                        </DialogActions>
                      </form>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>

              <Dialog open={openSauce} onClose={handleCloseSauce}>
                <DialogTitle>Add Sauce Option</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="sauce"
                    name="sauce"
                    label="Sauce name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      handleSauceAdd(e.target.value);
                    }}
                  />

                  <DialogActions>
                    <Button onClick={handleCloseSauce}>Cancel</Button>
                    <Button onClick={submitSauce} type="submit">
                      Submit
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>

              <Dialog open={openCrust} onClose={handleCloseCrust}>
                <DialogTitle>Add Crust Option</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="crust"
                    name="crust"
                    label="Crust name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      handleCrustAdd(e.target.value);
                    }}
                  />

                  <DialogActions>
                    <Button onClick={handleCloseCrust}>Cancel</Button>
                    <Button onClick={submitCrust} type="submit">
                      Submit
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>

              <Dialog open={openFlavour} onClose={handleCloseFlavour}>
                <DialogTitle>Add Flavour Option</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="flavour"
                    name="flavour"
                    label="Flavour name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      handleFlavourAdd(e.target.value);
                    }}
                  />

                  <DialogActions>
                    <Button onClick={handleCloseFlavour}>Cancel</Button>
                    <Button onClick={submitFlavour} type="submit">
                      Submit
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>

              <div>
                <Dialog open={opened} onClose={handleClickClosed}>
                  <DialogTitle>Add Coupon</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Add Chef/Restaurent discount coupon for menu.
                    </DialogContentText>
                    <Box sx={{ minWidth: 120, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Coupon Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={couponType}
                          label="Coupon Type"
                          onChange={(e) => {
                            setcouponType(e.target.value);
                          }}
                        >
                          <MenuItem value="percentage">Percentage</MenuItem>
                          <MenuItem value="amount">Amount (Default)</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        setcouponName(e.target.value);
                      }}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Coupon Code"
                      type="text"
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        setcouponCode(e.target.value);
                      }}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Amount or Percentage"
                      type="number"
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        setcouponAmount(e.target.value);
                      }}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Duration in months"
                      type="number"
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Max redemptions"
                      type="number"
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        setRedemptions(e.target.value);
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClickClosed}>Cancel</Button>
                    <Button onClick={submitCoupon}>Submit</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  } else {
    return <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color="warning" />
    </Box>;
  }
}