import "./EditOrder.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useState } from 'react';
import moment from 'moment';
import { updateOrder } from '../../shared/api/user';
import { useParams } from "react-router-dom";
import { getSingleOrder } from '../../shared/api/user';
import { useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import CustomizedSnackbars from '../Snackbar/Snackbar';
import { inputLabelClasses } from "@mui/material/InputLabel";


export default function BasicTable() {
    const [orders, setOrders] = useState([]);
    let [dishes, setDishes] = useState([]);
    const [addons, setAddons] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    let [count, setCount] = useState(0);
    const [notes, setNotes] = useState('');
    const [error, setError] = useState("");
    const [Interval, setInterval] = useState(0);
    const style = {
        "& .MuiOutlinedInput-root": {
            marginBottom: '10px',
            "&.Mui-focused fieldset": {
                borderColor: "#CB2704",
            }
        }
    }

    const init = async () => {
        let rows = await getSingleOrder(id);
        setOrders(rows.data.order);
        let dishes = [];
        let addons = [];
        setDishes(dishes);
        rows.data.order.orderItems.forEach((e => {
            e.dish.quantity = e.quantity;
            dishes.push(e.dish);
        }));
        rows.data.order.orderItems.forEach((e => {
            e.addons.forEach((a => {
                e.dish.addons.forEach((addon => {
                    if (a.addon === addon._id) {
                        addon.quantity = a.quantity;
                        addons.push(addon);
                    }
                }))
            }))
        }));
        setAddons(addons);
    }

    function incrementDishCount(id) {
        orders.orderItems.forEach((e => {
            if (id === e.dish._id) {
                e.quantity = e.quantity + 1;
                orders.subTotal = orders.subTotal + e.dish.price;
            }
        }))
        let dishes = [];
        let addons = [];
        setDishes(dishes)
        orders.orderItems.forEach((e => {
            e.dish.quantity = e.quantity;
            dishes.push(e.dish);
        }))
        orders.orderItems.forEach((e => {
            e.addons.forEach((a => {
                e.dish.addons.forEach((addon => {
                    if (a.addon === addon._id) {
                        addon.quantity = a.quantity;
                        addons.push(addon);
                    }
                }))
            }))
        }))
        setAddons(addons)
        calculateOrderPricing();
    }
    function decrementDishCount(id) {
        orders.orderItems.forEach((e => {
            if (e.quantity > 0) {
                if (id === e.dish._id) {
                    e.quantity = e.quantity - 1
                    orders.subTotal = orders.subTotal - e.dish.price;
                }
            }
        }))
        let dishes = [];
        let addons = [];
        setDishes(dishes)
        orders.orderItems.forEach((e => {
            e.dish.quantity = e.quantity;
            dishes.push(e.dish);
        }))
        orders.orderItems.forEach((e => {
            e.addons.forEach((a => {
                e.dish.addons.forEach((addon => {
                    if (a.addon === addon._id) {
                        addon.quantity = a.quantity;
                        addons.push(addon);
                    }
                }))
            }))
        }))
        setAddons(addons)
        calculateOrderPricing();
    }

    function incrementAddonCount(addon) {
        orders.orderItems.forEach((e => {
            e.addons.forEach((a => {
                if (addon._id === a.addon) {
                    a.quantity = a.quantity + 1;
                    orders.subTotal = orders.subTotal + addon.addonPrice;
                }
            }))
        }))
        let dishes = [];
        let addons = [];
        setDishes(dishes)
        orders.orderItems.forEach((e => {
            e.dish.quantity = e.quantity;
            dishes.push(e.dish);
        }))
        orders.orderItems.forEach((e => {
            e.addons.forEach((a => {
                e.dish.addons.forEach((addon => {
                    if (a.addon === addon._id) {
                        addon.quantity = a.quantity;
                        addons.push(addon);
                    }
                }))
            }))
        }))
        setAddons(addons)
        calculateOrderPricing();
    }
    function decrementAddonCount(addon) {
        orders.orderItems.forEach((e => {
            e.addons.forEach((a => {
                if (a.quantity > 0) {
                    if (addon._id === a.addon) {
                        a.quantity = a.quantity - 1;
                        orders.subTotal = orders.subTotal - addon.addonPrice;
                    }
                }
            }))
        }))

        let dishes = [];
        let addons = [];
        setDishes(dishes)
        orders.orderItems.forEach((e => {
            e.dish.quantity = e.quantity;
            dishes.push(e.dish);
        }))
        orders.orderItems.forEach((e => {
            e.addons.forEach((a => {
                e.dish.addons.forEach((addon => {
                    if (a.addon === addon._id) {
                        addon.quantity = a.quantity;
                        addons.push(addon);
                    }
                }))
            }))
        }))
        setAddons(addons)
        calculateOrderPricing();
    }

    function removeDish(dish) {
        let index = 0;
        let addons = [];
        orders.orderItems.forEach((e => {
            if (dish._id === e.dish._id) {
                orders.subTotal = orders.subTotal - e.dish.price;
                orders.orderItems.splice(index, 1);
            }
            index++;
        }))
        dishes = [];
        orders.orderItems.forEach((e => {
            dishes.push(e.dish);
        }))
        setDishes(dishes)
        calculateOrderPricing();
    }

    function removeAddon(addon) {
        let index = 0;
        let addons = [];
        let addonIndex = 0;
        orders.orderItems.forEach((e => {
            e.addons.forEach((a => {
                if (addon._id === a.addon) {
                    orders.subTotal = orders.subTotal - addon.addonPrice;
                    e.addons.splice(addonIndex, 1);
                }
                addonIndex++;
            }))
            index++;
        }))
        addons = [];
        orders.orderItems.forEach((e => {
            e.addons.forEach((a => {
                e.dish.addons.forEach((addon => {
                    if (a.addon === addon._id) {
                        addon.quantity = a.quantity;
                        addons.push(addon);
                    }
                }))
            }))
        }))
        setAddons(addons);
        calculateOrderPricing();
    }

    function calculateOrderPricing() {
        const taxAmount = Number(((orders.subTotal / 100) * orders.taxPercentage).toFixed(2));
        const chefPayToDvour = Number(((orders.subTotal / 100) * orders.serviceFeePercentage).toFixed(2));
        const chefServiceFee = Number(((orders.subTotal / 100) * orders.chefServiceFeePercentage).toFixed(2));
        const total = orders.subTotal + orders.taxAmount;
        orders.taxAmount = taxAmount;
        orders.serviceFee = chefPayToDvour;
        orders.total = total;
        orders.chefEarning = orders.subTotal - chefPayToDvour;
        orders.dvourEarning = chefPayToDvour + taxAmount;
        orders.chefServiceFee = chefServiceFee;
        console.log(orders);
    }

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (orders && orders.orderNotes) {
            setNotes(orders.orderNotes);
        }
    }, [orders]);

    async function updateOrderDetails(id) {
        let status = await updateOrder(id, { orderStatus: 'CANCELLED' });
        navigate(`/order/${id}`);
    }

    const updateOrderbyid = async (id) => {
        if (!notes) {
            setError('Please write some notes to continue...');
            setTimeout(() => {
                setError('');
            }, 6000);
        } else {
            let body = {
                taxAmount: orders.taxAmount,
                serviceFee: orders.serviceFee,
                total: orders.total,
                chefEarning: orders.chefEarning,
                dvourEarning: orders.dvourEarning,
                chefServiceFee: orders.chefServiceFee,
                orderItems: orders.orderItems,
                changeRequest: true,
                notes: notes,
                interval: Interval,
            };
            let status = await updateOrder(id, body);
            navigate(`/order/${id}`);
        }
    }

    return (
        <div>
            {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
            <div className='content-div'>
                <div>
                    <TableContainer className='table-container data-table' component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='table-head'>
                                <TableRow>
                                    <TableCell className='table-call-row' >Dish Name</TableCell>
                                    <TableCell className='table-call-row' align="right">Quantity</TableCell>
                                    <TableCell className='table-call-row' align="right">Dish Price</TableCell>
                                    <TableCell className='table-call-row' align="right">Remove Item</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dishes.map((row) => (
                                    <TableRow
                                        key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell className='table-call-row-data' component="th" scope="row">{row.dishName.substring(0, 25)}...</TableCell>
                                        <TableCell className='table-call-row-data' align="right">
                                            <div className='plus-btn'>
                                                <button className='btn-inc' onClick={() => incrementDishCount(row._id)}>+</button>
                                                <div className='quantity-count'>{row.quantity}</div>
                                                <button className='btn-inc' onClick={() => decrementDishCount(row._id)}>-</button>
                                            </div>
                                        </TableCell>
                                        <TableCell className='table-call-row-data' align="right">${row.price}</TableCell>
                                        <TableCell className='table-call-row-data' align="right"><Button onClick={() => removeDish(row)} variant="text"><DeleteIcon sx={{ color: '#CB2704' }}></DeleteIcon></Button></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {addons.length > 0 &&
                        <TableContainer className='table-container' sx={{ mt: 3 }} component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead className='table-head'>
                                    <TableRow>
                                        <TableCell className='table-call-row' >Addon Name</TableCell>
                                        <TableCell className='table-call-row' align="right">Quantity</TableCell>
                                        <TableCell className='table-call-row' align="right">Addon Price</TableCell>
                                        <TableCell className='table-call-row' align="right">Remove Item</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {addons.map((row) => (
                                        <TableRow
                                            key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell className='table-call-row-data' component="th" scope="row">{row.addonName}</TableCell>
                                            <TableCell className='table-call-row-data' align="right">  <div className='plus-btn'>
                                                <button className='btn-inc' onClick={() => incrementAddonCount(row)}>+</button>
                                                <div className='quantity-count'>{row.quantity}</div>
                                                <button className='btn-inc' onClick={() => decrementAddonCount(row)}>-</button>
                                            </div></TableCell>
                                            <TableCell className='table-call-row-data' align="right">${row.addonPrice}</TableCell>
                                            <TableCell className='table-call-row-data' align="right"><Button onClick={() => removeAddon(row)} variant="text"><DeleteIcon sx={{ color: '#CB2704' }}></DeleteIcon></Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>
                <div>

                    <div className='pricing-model'>
                        <div className='details-top'>
                            <div className='title-details'>Order Details</div>
                            {orders.orderStatus === 'PENDING' && <Button onClick={() => updateOrderDetails(id)} sx={{ fontWeight: 600, mb: 2, color: '#CB2704', border: '1px solid #CB2704', "&:hover": { border: "1px solid red" } }} variant="outlined" startIcon={<ClearIcon sx={{ color: '#CB2704' }} />}>
                                Cancel Order
                            </Button>}
                        </div>
                        <div className='total-flex'>
                            <div><b>Customer Name</b></div>
                            <div>{orders.user?.firstName} {orders.user?.lastName}</div>
                        </div>
                        <div className='total-flex'>
                            <div><b>Order Time</b></div>
                            <div>{moment(orders.createdAt).format('MMMM Do YYYY, h:mm a')}</div>
                        </div>
                        <div className='total-flex'>
                            <div><b>Order Status</b></div>
                            <div>{orders.orderStatus}</div>
                        </div>
                        <div className='total-flex'>
                            <div><b>Order Type</b></div>
                            <div>{orders.orderType}</div>
                        </div>
                        <div className='total-flex'>
                            <div><b>Payment Status</b></div>
                            <div>{orders.paymentStatus}</div>
                        </div>
                        <div className='total-flex'>
                            <div><b>Sub Total</b></div>
                            <div>${orders.subTotal}</div>
                        </div>
                        <div className='total-flex'>
                            <div><b>Service Fee & Other Charges</b></div>
                            <div>${orders.chefServiceFee}</div>
                        </div>
                        <div className='total-flex'>
                            <div><b>Total</b></div>
                            <div><b>${(orders.subTotal + orders.chefServiceFee).toFixed(2)}</b></div>
                        </div>
                        <div>
                            <TextField
                                sx={style}
                                InputLabelProps={{
                                    sx: {
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#CB2704"
                                        }
                                    }
                                }}
                                fullWidth
                                id="note"
                                name='note'
                                placeholder='Notes'
                                value={notes}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                }}
                            />

                            <TextField
                                sx={style}
                                InputLabelProps={{
                                    sx: {
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#CB2704"
                                        }
                                    }
                                }}
                                fullWidth
                                id="interval"
                                name='interval'
                                placeholder='Increase cooking interval'
                                value={Interval}
                                onChange={(e) => {
                                    setInterval(e.target.value);
                                }}
                            />
                        </div>
                        <div className='btn-div'>
                            <button onClick={() => updateOrderbyid(orders._id)} className='btn-update-order'>Update Order</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}
