import * as React from 'react';
import "./Slots.css";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getMe } from '../../shared/api/user';
import InputAdornment from '@mui/material/InputAdornment';
import { Formik, Form } from 'formik';
import { updateUser } from '../../shared/api/user';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';

const theme = createTheme();

export default function Slots() {
    const [User, setUser] = useState([]);
    const navigate = useNavigate();
    const [day, setDay] = useState('');
    const [slot, setSlot] = useState({});
    const [timeOpen, setTimeOpen] = useState('');
    const [timeClose, setTimeClose] = useState('');
    const selectFieldStyles = {
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CB2704",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CB2704",
        },
    };
    const handleChange = (event) => {
        setDay(event.target.value);
    };
    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
        console.log(user)
    }
    useEffect(() => {
        init();
    }, []);

    const updateSlots = async () => {
        let DayFound = false;
        let sl = {
            day: day,
            timings: { open: timeOpen, close: timeClose }
        };
        setSlot(sl);
        User.slots.forEach(s => {
            if (s.day === sl.day) {
                s.timings.push(sl.timings);
                DayFound = true;
            }
        })
        if (!DayFound) {
            User.slots.push({
                day: day,
                timings: [{ open: timeOpen, close: timeClose }]
            });
        }
        let res = await updateUser(User._id, { slots: User.slots });
        init();
    };

    const deleteSlot = async (slot, time) => {
        User.slots.forEach(s => {
            if (s.day === slot.day) {
                let index = s.timings.findIndex(x => x.open === time);
                let slotIndex = User.slots.findIndex(x => x.day === slot.day);
                if (s.timings.length == 1) {
                    User.slots.splice(slotIndex, 1);
                } else {
                    s.timings.splice(index, 1);
                }
            }
        })
        let res = await updateUser(User._id, { slots: User.slots });
        init();
    };

    if (!User.email) {
        return <Box display="flex"
            justifyContent="center"
            alignItems="center">Loading</Box>
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img style={{ height: "85px", width: "85px", borderRadius: "50%" }} src={User.picture} ></img>
                    <Typography sx={{ mt: 2 }} component="h1" variant="h5">
                        Update Time Availability
                    </Typography>
                    {User.slots.map((row) => (
                        <div className='slots'>
                            <div className='day-name'>{row.day}</div>
                            <div className='slots-list'>

                                {row.timings.map((time) => (
                                    <div className='slot-data'>
                                        <div className='data'>{time.open} - {time.close},</div>
                                        <div className='delete-data'><DeleteIcon onClick={() => deleteSlot(row, time.open)} sx={{ color: '#CB2704' }}></DeleteIcon></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className='add-title'>Add Time Slot</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={[
                                'MobileTimePicker',
                            ]}
                        >
                            <div className='time-picker'>
                                <DemoItem label="Open">
                                    <MobileTimePicker onChange={(time) => {
                                        let timeOpen = moment(time.$d, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A')
                                        setTimeOpen(timeOpen)
                                    }} defaultValue={dayjs('2022-04-17T09:00')} />
                                </DemoItem>
                                <DemoItem label="Close">
                                    <MobileTimePicker onChange={(time) => {
                                        let timeClose = moment(time.$d, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A')
                                        setTimeClose(timeClose);
                                    }} defaultValue={dayjs('2022-04-17T17:00')} />
                                </DemoItem>
                            </div>
                        </DemoContainer>
                    </LocalizationProvider>
                    <Box sx={{ minWidth: 400, mt: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="selectday">Select Day</InputLabel>
                            <Select 
                                sx={selectFieldStyles}
                                labelId="selectday"
                                id="selectday"
                                value={day}
                                name='selectday'
                                label="Select Day"
                                onChange={handleChange}
                            >
                                <MenuItem value='Monday'>Monday</MenuItem>
                                <MenuItem value='Tuesday'>Tuesday</MenuItem>
                                <MenuItem value='Wednesday'>Wednesday</MenuItem>
                                <MenuItem value='Thursday'>Thursday</MenuItem>
                                <MenuItem value='Friday'>Friday</MenuItem>
                                <MenuItem value='Saturday'>Saturday</MenuItem>
                                <MenuItem value='Sunday'>Sunday</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Button
                        onClick={() => updateSlots()}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, background: '#CB2704', "&:hover": { background: "red" } }}
                    >
                        Update
                    </Button>
                </Box>
            </Container>
        </ThemeProvider>


    );
}