import * as React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Dashboard from './Components/Dashboard/Dashboard';
import Signin from './Components/SignIn/SignIn';
import Orders from './Components/Orders/Orders';
import Reports from './Components/Reports/Reports';
import Dishes from './Components/Dishes/Dishes';
import Kitchen from './Components/Kitchens/Kitchens';
import EditProfile from './Components/EditProfile/EditProfile';
import Reviews from './Components/Reviews/Reviews';
import AddDish from './Components/Add-dish/add-dish';
import ProgressOrder from './Components/ProgressOrder/ProgressOrder';
import CancelledOrder from './Components/CancelledOrder/CancelledOrder';
import CompletedOrder from './Components/CompletedOrder/completedOrder';
import NewOrder from './Components/NewOrders/NewOrders';
import AcceptOrder from './Components/AcceptOrder/AcceptOrder';
import Financial from './Components/Financial/Financial';
import OrderDetails from './Components/OrderDetails/orderDetails';
import EditOrder from './Components/EditOrder/EditOrder';
import Slots from './Components/Slots/Slots';
import EditDish from './Components/EditDish/EditDish';
import Signup from './Components/Signup/Signup';
import Categories from './Components/Categories/Categories'
import Forgot from './Components/forgot-password/forgot-password';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Signin />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact
          path="/dashboard"
          element={<Dashboard />}
          children={<Route path='' element={<Orders />}></Route>
          } />
        <Route exact
          path="/orders"
          element={<Dashboard />}
          children={<Route path='' element={<Orders />}>
          </Route>
          } />
        <Route exact
          path="/orders/new"
          element={<Dashboard />}
          children={<Route path='' element={<NewOrder />}>
          </Route>
          } />
        <Route exact
          path="/orders/accepted"
          element={<Dashboard />}
          children={<Route path='' element={<AcceptOrder />}>
          </Route>
          } />
        <Route exact
          path="/orders/progress"
          element={<Dashboard />}
          children={<Route path='' element={<ProgressOrder />}>
          </Route>
          } />
        <Route exact
          path="/orders/cancelled"
          element={<Dashboard />}
          children={<Route path='' element={<CancelledOrder />}>
          </Route>
          } />
        <Route exact
          path="/orders/completed"
          element={<Dashboard />}
          children={<Route path='' element={<CompletedOrder />}>
          </Route>
          } />
        <Route exact
          path="/dishes"
          element={<Dashboard />}
          children={<Route path='' element={<Dishes />}>
          </Route>
          } />
        <Route exact
          path="/kitchens"
          element={<Dashboard />}
          children={<Route path='' element={<Kitchen />}>
          </Route>
          } />
        <Route exact
          path="/profile"
          element={<Dashboard />}
          children={<Route path='' element={<EditProfile />}>
          </Route>
          } />
        <Route exact
          path="/reviews"
          element={<Dashboard />}
          children={<Route path='' element={<Reviews />}>
          </Route>
          } />
        <Route exact
          path="/dish"
          element={<Dashboard />}
          children={<Route path='' element={<AddDish />}>
          </Route>
          } />
        <Route exact
          path="/financial"
          element={<Dashboard />}
          children={<Route path='' element={<Financial />}>
          </Route>
          } />
        <Route exact
          path="/order/:id"
          element={<Dashboard />}
          children={<Route path='' element={<OrderDetails />}>
          </Route>
          } />
        <Route exact
          path="/order/edit/:id"
          element={<Dashboard />}
          children={<Route path='' element={<EditOrder />}>
          </Route>
          } />
        <Route exact
          path="/edit/slots"
          element={<Dashboard />}
          children={<Route path='' element={<Slots />}>
          </Route>
          } />
        <Route exact
          path="/dish/edit/:id"
          element={<Dashboard />}
          children={<Route path='' element={<EditDish />}>
          </Route>
          } />

        <Route exact
          path="categories"
          element={<Dashboard />}
          children={<Route path='' element={<Categories />}>
          </Route>
          } />
      </Routes>

    </Router>
  );
}
