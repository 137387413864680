import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { getProfitDetails } from '../../shared/api/user';
import { useState, useEffect } from 'react';

function preventDefault(event) {
  event.preventDefault();
}

export default function Deposits() {
  const [profit, setprofit] = useState([]);

  const init = async () => {
    let prof = await getProfitDetails();
    setprofit(prof.data);
    console.log(prof)
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <React.Fragment>
        <Typography component="p" variant="h4" sx={{ fontWeight: '600' }}>
          ${profit?.todaysSale?.toFixed(2)}
        </Typography>
        <Typography color="text.secondary" sx={{ flex: 1, fontWeight: '600' }}>
          Sales Today
        </Typography>
        <div>
        </div>
      </React.Fragment>
      <React.Fragment>
        <Typography component="p" variant="h4" sx={{ fontWeight: '600' }}>
          ${profit?.todaysProfit?.toFixed(2)}
        </Typography>
        <Typography color="text.secondary" sx={{ flex: 1, fontWeight: '600' }}>
          Profit Today
        </Typography>
      </React.Fragment>
    </div>
  );
}
