import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deleteOrder, getOrdersList, getProfitDetails } from '../../shared/api/user';
import { useState, useEffect } from 'react';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "../Orders/Orders.css";
import { updateOrder } from '../../shared/api/user';
import { useNavigate } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import "./AcceptOrder.css";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import toast, { Toaster } from 'react-hot-toast';

export default function BasicTable() {
  const [orders, setOrders] = useState([]);
  const [profit, setprofit] = useState([]);
  const [Offset, setOffset] = useState(0);
  const navigate = useNavigate();
  const [isempty, setisempty] = useState(false);
  const [isloading, setisloading] = useState(true);

  const init = async () => {
    let rows = await getOrdersList(Offset, 30, 'ACCEPTED');
    setOrders(rows.data.orders);
    if (!rows.data.orders.length) {
      setisempty(true)
    }
    setisloading(false);
  }

  useEffect(() => {
    init();
    const interval = setInterval(() => {
      init();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  async function deleteOrderById(id) {
    let status = await deleteOrder(id);
    init();
  }

  async function paginationClicked(e) {
    setOffset(Number(e.target.innerText * 10))
    let rows = await getOrdersList(Offset);
    let data = rows.data.orders.filter(function (e) {
      return e.orderStatus === 'ACCEPTED';
    });
    setOrders(data);
  }

  const details = async (id) => {
    navigate(`/order/${id}`);
  }

  const editDetails = async (id) => {
    navigate(`/order/edit/${id}`);
  }

  async function updateOrderById(id) {
    try {
      toast('Cooking started... 🔥');
      await updateOrder(id, { orderStatus: 'IN_PROGRESS' });
      init();
    }
    catch (err) {
      console.log(err)
    }
  }

  
  const paginationClickedNext = () =>  {
    setOffset((prevOffset) => prevOffset + 30);        
    init();
  }

  async function paginationClickedPrev() {
    if (Offset > 0) {
      setOffset(Offset - 30);
      init();
    }
  }


  return (
    <div>
      {isloading && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="warning" />
      </Box>}
      {(isempty && !isloading) && <div style={{ fontWeight: '600', textAlign: 'center' }}>No Accepted Orders Found!</div>}
      {(!isempty && !isloading) && <TableContainer className='table-container' component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className='table-head'>
            <TableRow>
              <TableCell className='table-call-row' >Dish</TableCell>
              <TableCell className='table-call-row' align="right">Customer Name</TableCell>
              <TableCell className='table-call-row' align="right">Dish Name</TableCell>
              <TableCell className='table-call-row' align="right">Order Status</TableCell>
              <TableCell className='table-call-row' align="right">Order Type</TableCell>
              <TableCell className='table-call-row' align="right">Payment Status</TableCell>
              <TableCell className='table-call-row' align="right">Total Price</TableCell>
              {/* <TableCell align="center">Details</TableCell> */}
              <TableCell className='table-call-row' align="right">Edit Order</TableCell>
              <TableCell className='table-call-row' align="right">Order Time</TableCell>
              <TableCell className='table-call-row' align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} size='small' component="th" scope="row">
                  <img style={{ height: "45px", width: "45px", borderRadius: "50%" }} src={row.orderItems[0]?.dish.picture} ></img>
                </TableCell >
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.user?.firstName} {row.user?.lastName}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderItems[0]?.dish.dishName.substring(0, 25)}...</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderStatus}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderType}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right"><span className={`${row?.paymentStatus === 'PAID' ? 'payment-status' : 'Npayment-status'}`}>{row?.paymentStatus}</span></TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">${(row.subTotal - row.chefServiceFee).toFixed(2)}</TableCell>
                {/* <TableCell align="right"><Button sx={{ color: '#CB2704' }} onClick={() => details(row._id)} variant="text"><InfoIcon sx={{ color: '#CB2704' }}></InfoIcon></Button></TableCell> */}
                <TableCell className='table-call-row-data' align="right"><Button onClick={() => editDetails(row._id)} variant="text"><EditIcon sx={{ color: '#CB2704' }}></EditIcon></Button></TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{moment(row.createdAt).format('MMM Do YY, h:mm a')}</TableCell>
                <TableCell className='table-call-row-data' align="right"><Button sx={{ color: '#CB2704', fontWeight: '600' }} onClick={() => updateOrderById(row._id)} variant="text">In Progress</Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box textAlign='center' mb={2} mt={2}>
          <Button onClick={() => paginationClickedNext()} variant="text" sx={{ color: '#CB2704', fontWeight: '600' }}>Load next</Button>
          {Offset > 0 && <Button onClick={() => paginationClickedPrev()} variant="text" sx={{ color: '#CB2704', fontWeight: '600' }}>Load previous</Button>}
        </Box>
      </TableContainer>}
      <Toaster />
    </div>
  );
}