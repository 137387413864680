import * as React from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import { deleteKitchen, getKitchensList } from '../../shared/api/user';
// import { useState, useEffect } from 'react';
// import moment from 'moment';
import "../Orders/Orders.css";
import commingSoon from '../../shared/images/comming-soon.png';

export default function BasicTable() {
  // const [Kitchens, setKitchens] = useState([]);
  // const init = async () => {
    // let rows = await getKitchensList();
    // setKitchens(rows.data.kitchens);
  // }
  // useEffect(() => {
  //   init();
  // }, []);

  // async function deleteKitchenById(id) {
  //   let status = await deleteKitchen(id);
  //   init();
  // }

  return (
    <div>
      <img style={{ display: 'block', margin: 'auto' }} src={commingSoon} ></img>
    </div>
    // <TableContainer className='table-container' component={Paper}>
    //   <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //     <TableHead className='table-head'>
    //       <TableRow>
    //         <TableCell className='table-call-row' >Kitchen</TableCell>
    //         <TableCell className='table-call-row' align="right">Kitchen Name</TableCell>
    //         <TableCell className='table-call-row' align="right">Owner Name</TableCell>
    //         <TableCell className='table-call-row' align="right">Phone</TableCell>
    //         <TableCell className='table-call-row' align="right">Website</TableCell>
    //         <TableCell className='table-call-row' align="right">Edit Kitchen</TableCell>
    //         <TableCell className='table-call-row' align="right">Delete Kitchen</TableCell>
    //         <TableCell className='table-call-row' align="right">Kitchen Added Time</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {Kitchens.map((row) => (
    //         <TableRow
    //           key={row._id}
    //           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    //         >
    //           <TableCell className='table-call-row-data' component="th" scope="row">
    //             <img style={{ height: "50px", width: "50px", borderRadius: "50%" }} src={row.picture} ></img>
    //           </TableCell>
    //           <TableCell className='table-call-row-data' align="right">{row.kitchenName}</TableCell>
    //           <TableCell className='table-call-row-data' align="right">{row.kitchenOwnerName}</TableCell>
    //           <TableCell className='table-call-row-data' align="right">{row.phoneNumber}</TableCell>
    //           <TableCell className='table-call-row-data' align="right">{row.websiteLink}</TableCell>
    //           <TableCell className='table-call-row-data' align="right"><Button variant="text"><EditIcon sx={{ color: '#CB2704' }}></EditIcon></Button></TableCell>
    //           <TableCell className='table-call-row-data' align="right"><Button onClick={() => deleteKitchenById(row._id)} variant="text"><DeleteIcon sx={{ color: '#CB2704' }}></DeleteIcon></Button></TableCell>
    //           <TableCell className='table-call-row-data' align="right">{moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
    //         </TableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
  );
}