import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { deleteOrder, getOrdersList, getProfitDetails } from '../../shared/api/user';
import { useState, useEffect } from 'react';
import moment from 'moment';
import "../Orders/Orders.css";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function BasicTable() {
  const [orders, setOrders] = useState([]);
  const [profit, setprofit] = useState([]);
  const [isempty, setisempty] = useState(false);
  const [isloading, setisloading] = useState(true);
  const init = async () => {
    let rows = await getOrdersList(0,30,'COMPLETED');
    console.log(rows)
    setOrders(rows.data.orders);
    let prof = await getProfitDetails();
    setprofit(prof);
    if (!rows.data.orders.length) {
      setisempty(true)
    }
    setisloading(false);
  }
  useEffect(() => {
    init();
  }, []);

  async function deleteOrderById(id) {
    let status = await deleteOrder(id);
    init();
  }

  return (
    <div>
  {isloading && (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color="warning" />
    </Box>
  )}
  {isempty && !isloading && (
    <div style={{ fontWeight: '600', textAlign: 'center' }}>
      You do not have any reviews yet!
    </div>
  )}
  {!isempty && !isloading && (
    <TableContainer className='table-container' component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className='table-head'>
          <TableRow>
            <TableCell className='table-call-row'>Dish</TableCell>
            <TableCell className='table-call-row' align="right">Customer Name</TableCell>
            <TableCell className='table-call-row' align="right">Dish Name</TableCell>
            <TableCell className='table-call-row' align="right">Review message</TableCell>
            <TableCell className='table-call-row' align="right">Rating</TableCell>
            <TableCell className='table-call-row' align="right">Order Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders
            .filter(row => row.feedback.message.trim() !== '') // Filter out reviews with empty messages
            .map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table-call-row-data' size='small' component="th" scope="row">
                  <img style={{ height: "45px", width: "45px", borderRadius: "50%" }} src={row.orderItems[0]?.dish.picture} alt="Dish" />
                </TableCell>
                <TableCell className='table-call-row-data' align="right">{row.user?.firstName} {row.user?.lastName}</TableCell>
                <TableCell className='table-call-row-data' align="right">{row.orderItems[0]?.dish.dishName}</TableCell>
                <TableCell className='table-call-row-data' align="right">{row.feedback.message}</TableCell>
                <TableCell className='table-call-row-data' align="right">{row.feedback.rating}</TableCell>
                <TableCell className='table-call-row-data' align="right">{moment(row.createdAt).format('MMMM Do, h:mm a')}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )}
</div>

  );
}