import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { deleteOrder, getOrdersList, getProfitDetails } from '../../shared/api/user';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "./Orders.css";
import { useNavigate } from "react-router-dom";
import logo from "../../d-vour2.png";
import { dividerClasses } from '@mui/material';
import { fontWeight } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TopDishes from '../Topdishes/Topdishes';
import tone from '../../audio/notify.mp3';
import toast, { Toaster } from 'react-hot-toast';
import Button from '@mui/material/Button';

export default function BasicTable() {
  const [orders, setOrders] = useState([]);
  const [profit, setProfit] = useState([]);
  const [Offset, setOffset] = useState(0);
  const [latestOrderTime, setLatestOrderTime] = useState(null);
  const [isempty, setisempty] = useState(false);
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(true);

  // Create a ref to store the previous orders
  const previousOrdersRef = useRef([]);

  // Create a state variable to track if a new order was added during fetch
  const [isNewOrderAdded, setIsNewOrderAdded] = useState(false);
  const [notificationSent, setNotificationSent] = useState(false);

  const fetchOrders = async () => {
    let rows = await getOrdersList(Offset, 15, '');
    setOrders(rows.data.orders);

    if (!rows.data.orders.length) {
      setisempty(true)
    }
    // Check if there are any new orders by comparing timestamps
    const newOrders = rows.data.orders.filter(order => !previousOrdersRef.current.some(prevOrder => prevOrder._id === order._id));

    if (newOrders.length > 0) {
      const latestOrderTimestamp = moment(newOrders[0].createdAt).valueOf();
      const lastNotificationTimestamp = localStorage.getItem('lastNotificationTimestamp');
      if (lastNotificationTimestamp === null || latestOrderTimestamp > Number(lastNotificationTimestamp)) {
        setLatestOrderTime(moment(newOrders[0].createdAt).format('YYYY-MM-DD HH:mm:ss'));
        setIsNewOrderAdded(true); // Set the flag to true if a new order was added
        setNotificationSent(false); // Reset the notificationSent flag when a new order is added
        localStorage.setItem('lastNotificationTimestamp', latestOrderTimestamp); // Store the latest order timestamp in localStorage
      }
    }

    // Update the previousOrdersRef with the current orders
    previousOrdersRef.current = rows.data.orders;
    setisloading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [Offset]);

  useEffect(() => {
    // Fetch orders every 20 seconds
    const interval = setInterval(() => {
      fetchOrders();
    }, 20000);

    // Get previous orders from localStorage
    const previousOrders = JSON.parse(localStorage.getItem('previousOrders'));
    // Update the previousOrdersRef with the previous orders
    previousOrdersRef.current = previousOrders || [];

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Show notification when a new order is added
    if (isNewOrderAdded && !notificationSent) {
      showNotification();
      setNotificationSent(true);
    }
  }, [isNewOrderAdded, notificationSent]);

  // useEffect(() => {
  //   // Check if the browser supports notifications and request permission if needed
  //   if ('Notification' in window && Notification.permission !== 'granted') {
  //     Notification.requestPermission();
  //   }
  // }, []);

  async function deleteOrderById(id) {
    let status = await deleteOrder(id);
    fetchOrders();
  }

  const details = async (id) => {
    navigate(`/order/${id}`);
  }

  const editDetails = async (id) => {
    navigate(`/order/edit/${id}`);
  }

  async function paginationClicked(e) {
    setOffset(Number(e.target.innerText * 10));
  }

  const notify = () => toast('A new order has been received.');

  const showNotification = async () => {
    let audio = new Audio(tone);
    audio.play();
    notify();
  }


  const paginationClickedNext = () => {
    setOffset((prevOffset) => prevOffset + 30);
    fetchOrders();
  }

  async function paginationClickedPrev() {
    if (Offset > 0) {
      setOffset(Offset - 30);
      fetchOrders();
    }
  }

  return (
    <div>
      {(!isempty && !isloading) && <TopDishes />}
      {isloading && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="warning" />
      </Box>}
      {(isempty && !isloading) && <div style={{ fontWeight: '600', textAlign: 'center' }}>No Orders Found!</div>}
      {(!isempty && !isloading) && <TableContainer className='table-container' component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className='table-head'>
            <TableRow>
              <TableCell className='table-call-row'>Dish</TableCell>
              <TableCell className='table-call-row' align="right">Customer Name</TableCell>
              <TableCell className='table-call-row' align="right">Dish Name</TableCell>
              <TableCell className='table-call-row' align="right">Order Status</TableCell>
              <TableCell className='table-call-row' align="right">Order Type</TableCell>
              <TableCell className='table-call-row' align="right">Payment Status</TableCell>
              <TableCell className='table-call-row' align="right">Total Price</TableCell>
              {/* <TableCell align="center">Details</TableCell> */}
              {/* <TableCell align="right">Edit Order</TableCell> */}
              <TableCell className='table-call-row' align="right">Order Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} size='small' component="th" scope="row">
                  <img style={{ height: "45px", width: "45px", borderRadius: "50%" }} src={row?.orderItems[0]?.dish?.picture} ></img>
                </TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row?.user?.firstName} {row.user?.lastName}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row?.orderItems[0]?.dish?.dishName.substring(0, 25)}...</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row?.orderStatus}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row?.orderType}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right"><span className={`${row?.paymentStatus === 'PAID' ? 'payment-status' : 'Npayment-status'}`}>{row?.paymentStatus}</span></TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">${(row?.subTotal - row.chefServiceFee).toFixed(2)}</TableCell>
                {/* <TableCell align="right"><Button sx={{ color: '#CB2704' }} onClick={() => details(row._id)} variant="text"><InfoIcon sx={{ color: '#CB2704' }}></InfoIcon></Button></TableCell> */}
                {/* <TableCell align="right"><Button onClick={() => editDetails(row._id)} variant="text"><EditIcon sx={{ color: '#CB2704' }}></EditIcon></Button></TableCell> */}
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{moment(row.createdAt).format('MMM Do YY, h:mm a')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box textAlign='center' mb={2} mt={2}>
          <Button onClick={() => paginationClickedNext()} variant="text" sx={{ color: '#CB2704', fontWeight: '600' }}>Load next</Button>
          {Offset > 0 && <Button onClick={() => paginationClickedPrev()} variant="text" sx={{ color: '#CB2704', fontWeight: '600' }}>Load previous</Button>}
        </Box>
      </TableContainer>}
      <Toaster />
    </div>

  );
}
