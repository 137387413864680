import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KitchenIcon from '@mui/icons-material/Kitchen';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import RateReviewIcon from '@mui/icons-material/RateReview';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import toggleDrawer from '../Dashboard/Dashboard';

export default function NestedList() {
  const [open, setOpen] = React.useState(true);
  const text = {
    fontWeight: "600"
};
  const handleClick = () => {
    setOpen(!open);
    toggleDrawer();
  };

  return (
    <React.Fragment>
      <ListItemButton to="/dashboard">
        <ListItemIcon sx={{ color: '#CB2704' }}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: text }} primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={handleClick} >
      <ListItemIcon sx={{ color: '#CB2704' }}>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: text }} primary="Orders" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }} to="/orders">
            <ListItemIcon sx={{ color: '#CB2704' }}>
              <RestaurantMenuIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: text }} primary="All" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} to="/orders/new">
            <ListItemIcon sx={{ color: '#CB2704' }}>
              <MicrowaveIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: text }} primary="New" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} to="/orders/accepted">
            <ListItemIcon sx={{ color: '#CB2704' }}>
              <BeenhereIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: text }} primary="Accepted" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} to="/orders/progress">
            <ListItemIcon sx={{ color: '#CB2704' }}>
              <RamenDiningIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: text }} primary="In Progress" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} to="/orders/completed">
            <ListItemIcon sx={{ color: '#CB2704' }}>
              <RestaurantIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: text }} primary="Completed" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} to="/orders/cancelled">
            <ListItemIcon sx={{ color: '#CB2704' }}>
              <NoMealsIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: text }} primary="Cancelled" />
          </ListItemButton>
        </List>
      </Collapse>
      {/* Kitchens disabled */}
      {/* <ListItemButton to="/kitchens">
        <ListItemIcon sx={{ color: '#CB2704' }}>
          <KitchenIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: text }} primary="Kitchens" />
      </ListItemButton> */}
      <ListItemButton to="/dishes">
        <ListItemIcon sx={{ color: '#CB2704' }}>
          <FastfoodIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: text }} primary="Dishes" />
      </ListItemButton>
      <ListItemButton to="/reviews">
        <ListItemIcon sx={{ color: '#CB2704' }}>
          <RateReviewIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: text }} primary="Reviews" />
      </ListItemButton>
      <ListItemButton to="/financial">
        <ListItemIcon sx={{ color: '#CB2704' }}>
          <AttachMoneyIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: text }} primary="Financials" />
      </ListItemButton>
    </React.Fragment>
  );
}
