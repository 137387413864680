import * as React from 'react';
import "./Categories.css";
import { useState } from 'react';
import moment from 'moment';
import { getMe, updateUser } from '../../shared/api/user';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { inputLabelClasses } from "@mui/material/InputLabel";
import DeleteIcon from '@mui/icons-material/Delete';


export default function Category() {
    const [user, setUser] = useState([]);
    const [categoryDialog, setcategoryDialog] = useState(false);
    const [categoryName, setcategoryName] = useState([]);
    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#CB2704"
            }
        }
    }
    const getDelete= async (e) =>{
        user.categories.forEach(element => {
            if (element===e.target.innerText){
                var index = user.categories.indexOf(element);
                user.categories.splice(index, 1);
            }
        });
        await updateUser(user._id, { categories: user.categories});
        init();
    }
    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
    }
    const handleClickClosed = () => {
        setcategoryDialog(false);
    }
    const submit = async () => {
        await updateUser(user._id, { categories: user.categories.concat(categoryName) });
        setcategoryDialog(false)
        init();
    }
    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            <div>
                <div className='pricing'>
                    <button onClick={() => setcategoryDialog(true)} className='cat-btn' ><AddIcon sx={{ color: '#ee3044' }} /></button>
                    <div className='title-detail'>Categories</div>
                    <div className='total-flex'>
                        <div className='cat-box'>
                            {user?.categories?.map((row) =>
                           
                                <div className='cat-list'>
                                    <div onClick={(e) => getDelete(e)}>{row}<DeleteIcon sx={{ color: '#CB2704', float: 'left', fontSize: '19px' }} ></DeleteIcon></div>
                                </div>)}
                        </div>

                    </div>
                    <h5>Note: Click on the catogory name to delete.</h5>

                </div>
                <Dialog open={categoryDialog} onClose={handleClickClosed}>
                    <DialogTitle>Add Category </DialogTitle>
                    <DialogContent>
                        <TextField
                            sx={style}
                            InputLabelProps={{
                                sx: {
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#CB2704"
                                    }
                                }
                            }}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Category Name"
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setcategoryName(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button className='inmenu-btn' onClick={handleClickClosed}>Cancel</Button>
                        <Button className='inmenu-btn' onClick={() => submit()}  >Submit</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}