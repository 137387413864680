import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getMe, getCuisine } from '../../shared/api/user';
import { Formik } from 'formik';
import { updateUser } from '../../shared/api/user';
import { inputLabelClasses } from "@mui/material/InputLabel";
import EditIcon from '@mui/icons-material/Edit';
import "./EditProfile.css";
import Autocomplete from './Autocomplete/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef } from 'react';
import { uploadImageWithoutSize, uploadImage, verifyEmail, verificationEmail } from '../../shared/api/user';
import defaultCover from '../../shared/images/default-cover.png'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const style = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#CB2704"
    }
  }
}
const theme = createTheme();

export default function SignUp() {

  const [User, setUser] = useState([]);
  const navigate = useNavigate();
  const [UpdatePicture, setUpdatePicture] = React.useState(false);
  const [UpdateCover, setUpdateCover] = React.useState(false);
  const [onFileSelect, setonFileSelect] = useState();
  const [onCoverSelect, setonCoverSelect] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [err, seterr] = useState('');
  const [Cuisines, setCuisines] = useState([]);
  const [cuisineId, setCuisineId] = React.useState('');
  const [open, setOpen] = useState(false);
  const [verificationCode, setVarificationCode] = useState('');
  const [error, setError] = useState("");

  const init = async () => {
    let user = await getMe();
    let cuisine = await getCuisine();
    setCuisines(cuisine.data.cuisines);
    setUser(user.data.user);

  }

  useEffect(() => {
    init();
  }, []);

  const handleClickClosed = () => {
    setUpdatePicture(false)
    setUpdateCover(false);
  };

  const fileInputRef = useRef(null);

  const handleFileSelect = async () => {
    fileInputRef.current.click();

  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setonFileSelect(selectedFile);
    setSelectedImage(URL.createObjectURL(selectedFile));
  };

  const handleCoverChange = (e) => {
    const selectedCoverImage = e.target.files[0];
    setonCoverSelect(selectedCoverImage);
    setSelectedCoverImage(URL.createObjectURL(selectedCoverImage));
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleEmailverification = () => {
    setOpen(true);
    verifyEmail({ email: User.email });
  };

  const submitVerification = async () => {
    try {
      await verificationEmail({ emailVerificationCode: verificationCode, email: User.email });
      setOpen(false)
      window.location.reload();
    } catch (err) {
      setError('Invalid Verification Code!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  }

  const Submit = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", onFileSelect);
      let res = await uploadImageWithoutSize(formData);
      let url = res.data.data.url;
      await updateUser(User._id, { picture: url });
      setUpdatePicture(false);
      window.location.reload();
    }
    else {
      seterr("Select a picture before submitting..!");
    }

  }

  const SubmitCover = async () => {
    if (selectedCoverImage) {
      const formData = new FormData();
      formData.append("file", onCoverSelect);
      let res = await uploadImage(formData);
      let url = res.data.data.url;
      await updateUser(User._id, { coverPhoto: url });
      setUpdatePicture(false);
      window.location.reload();
    }
    else {
      seterr("Select a picture before submitting..!");
    }
  }

  const OpenpicDialoge = () => {
    setUpdatePicture(true)

  }

  const OpenCoverDialoge = () => {
    setUpdateCover(true)

  }
  if (!User.email) {
    return <Box display="flex"
      justifyContent="center"
      alignItems="center">Loading</Box>
  }
  return (
    <ThemeProvider theme={theme}>
      {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
      <Container className='edit-prof-cnt' component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 26,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!User.coverPhoto && <img className='cover-image' src={defaultCover} onClick={() => OpenCoverDialoge()}></img>}
          {User.coverPhoto && <img className='cover-image' src={User.coverPhoto} onClick={() => OpenCoverDialoge()}></img>}
          <img style={{ height: "85px", width: "85px", borderRadius: "50%", zIndex: '10000' }} src={User.picture} ></img>
          <button className='btn-edit-pic' onClick={() => OpenpicDialoge()}>
            <EditIcon></EditIcon>
          </button>
          <Dialog open={UpdateCover} onClose={handleClickClosed}>
            <DialogTitle>Update Cover Picture</DialogTitle>
            <DialogContent>
              <div className='update-pic-dialoge'>
                <p className='update-pic-p'>Drop your photo here, or
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleCoverChange}
                  />
                  <button onClick={handleFileSelect} className='update-pic-Browse-btn'>Browse</button> <br></br> JPG or PNG format Max 5 MB.</p>
              </div>
              {selectedCoverImage && <img className='update-pic-preview' src={selectedCoverImage} alt="Selected" />}
            </DialogContent>
            <div className='error-message'>{err}</div>
            <DialogActions>
              <Button onClick={handleClickClosed}>Cancel</Button>
              <Button onClick={SubmitCover}>Submit</Button>
            </DialogActions>

          </Dialog>
          <Dialog open={UpdatePicture} onClose={handleClickClosed}>
            <DialogTitle>Update Picture</DialogTitle>
            <DialogContent>
              <div className='update-pic-dialoge'>
                <p className='update-pic-p'>Drop your photo here, or
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <button onClick={handleFileSelect} className='update-pic-Browse-btn'>Browse</button> <br></br> JPG or PNG format Max 5 MB.</p>
              </div>
              {selectedImage && <img className='update-pic-preview' src={selectedImage} alt="Selected" />}
            </DialogContent>
            <div className='error-message'>{err}</div>
            <DialogActions>
              <Button onClick={handleClickClosed}>Cancel</Button>
              <Button onClick={Submit}>Submit</Button>
            </DialogActions>

          </Dialog>
          <Typography sx={{ mt: 1, mb: 1, fontWeight: '600' }} component="h1" variant="h5">
            Update Profile
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Formik initialValues={{ firstName: User.firstName, lastName: User.lastName, email: User.email, paypalEmail: User.paypalEmail, experience: User.experience, about: User.about, restaurantName: User.restaurantName, phoneNumber: User.phoneNumber, cuisine: User.cuisine }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(async () => {
                    console.log(values)
                    setSubmitting(false);
                    let res = await updateUser(User._id, values);
                    console.log(res)
                    navigate("/dashboard");
                  }, 400);
                }}
              >
                {({ handleSubmit, handleChange, isSubmitting, enableReinitialize, values }) => (
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
                    {
                      User.chefType === 'PROFESSIONAL_CHEF' &&
                      <Box sx={{ display: 'flex', mb: 2, justifyContent: 'space-between' }}>
                        <Grid item xs={11} sm={5}>
                          <TextField
                            sx={style}
                            InputLabelProps={{
                              sx: {
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "#CB2704"
                                }
                              }
                            }}
                            autoComplete="given-name"
                            name="firstName"
                            placeholder='First Name'
                            value={values.firstName}
                            fullWidth
                            id="firstName"
                            autoFocus
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={11} sm={5}>
                          <TextField
                            sx={style}
                            InputLabelProps={{
                              sx: {
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "#CB2704"
                                }
                              }
                            }}
                            fullWidth
                            placeholder='Last Name'
                            id="lastName"
                            name="lastName"
                            value={values.lastName}
                            autoComplete="family-name"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Box>
                    }


                    {
                      User.chefType === 'RESTAURANT' &&
                      <Grid sx={{ mb: 1 }} item xs={12}>
                        <InputLabel id="demo-simple-select-label">
                          Restaurant Name
                        </InputLabel>
                        <TextField
                          sx={style}
                          InputLabelProps={{
                            sx: {
                              [`&.${inputLabelClasses.shrink}`]: {
                                color: "#CB2704"
                              }
                            }
                          }}
                          fullWidth
                          id="restaurantName"
                          name='restaurantName'
                          placeholder='Restaurant Name'
                          value={values.restaurantName}
                          onChange={handleChange}
                        />
                      </Grid>
                    }

                    <Grid sx={{ mb: 1 }} item xs={12}>
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InputLabel id="demo-simple-select-label">
                          Email Address
                        </InputLabel>
                        {!User.isEmailVerified && <a onClick={() => handleEmailverification()} style={{ color: '#CB2704', cursor: 'pointer' }}>Verify Email</a>}
                      </Box>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704"
                            }
                          }
                        }}
                        fullWidth
                        id="email"
                        name="email"
                        placeholder='Email'
                        value={values.email}
                        autoComplete="email"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Dialog open={open} onClose={handleClickClose}>
                      <DialogTitle>Email Verification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          A verification code has been sent to your email {User.email}. Please enter verification code below to verify your email adress.
                        </DialogContentText>
                        <TextField
                          sx={style}
                          InputLabelProps={{
                            sx: {
                              [`&.${inputLabelClasses.shrink}`]: {
                                color: "#CB2704"
                              }
                            }

                          }}
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Verification code"
                          type="text"
                          fullWidth
                          onChange={(e) => {
                            setVarificationCode(e.target.value);
                          }}
                        />

                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClickClose}>Cancel</Button>
                        <Button onClick={submitVerification}>Submit</Button>
                      </DialogActions>
                    </Dialog>
                    <Grid sx={{ mb: 1 }} item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Phone Number
                      </InputLabel>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704"
                            }
                          }
                        }}
                        fullWidth
                        id="phoneNumber"
                        name='phoneNumber'
                        placeholder='Phone Number'
                        value={values.phoneNumber}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid sx={{ mb: 1 }} item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        PayPal Email
                      </InputLabel>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704"
                            }
                          }
                        }}
                        fullWidth
                        id="paypalEmail"
                        name='paypalEmail'
                        placeholder='Paypal Email'
                        value={values.paypalEmail}
                        onChange={handleChange}
                        disabled
                      />
                    </Grid>
                    <Grid sx={{ mb: 1 }} item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Description
                      </InputLabel>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704"
                            }
                          }
                        }}
                        fullWidth
                        id="about"
                        name='about'
                        placeholder='Description'
                        value={values.about}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid sx={{ mb: 1 }} item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Cuisine
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="cuisine"
                        name="cuisine"
                        value={values.cuisine}
                        label="Cuisine"
                        onChange={handleChange}
                      >
                        {Cuisines.map((Cuisine) => (
                          <MenuItem id={Cuisine._id} value={Cuisine.title}>
                            {Cuisine.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid sx={{ mb: 1 }} item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Experience
                      </InputLabel>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704"
                            }
                          }
                        }}
                        fullWidth
                        id="experience"
                        name='experience'
                        placeholder='Experience'
                        value={values.experience}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Autocomplete />
                    <Button
                      type="submit" disabled={isSubmitting}
                      fullWidth
                      variant="contained"
                      sx={{ mt: 2, mb: 2, background: '#CB2704', "&:hover": { background: "red" } }}
                    >
                      Submit
                    </Button>
                  </form>)}

              </Formik>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}